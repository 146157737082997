import React from 'react';
import ScrollCarouselComponent from './ScrollCarouselComponent';
// import dynamic from 'next/dynamic';

// const ScrollCarousel = dynamic(() => import('./ScrollCarouselComponent'), { ssr: false });

const Customers = () => {
  return (
    <div className='w-full bg-white flex justify-center overflow-hidden'>
        <div className='flex h-[300px] flex-col items-center justify-between bg-white pb-6 '>
          <div className='text-center'>
            <p className='text-xl font-bold pt-5'>مشتریان ما، اعتبار ما</p>
          </div>
          <ScrollCarouselComponent />
        </div>
    </div>
  );
};

export default Customers;