import Menu from "./menu";


export const siteTitle = "Datis Pars";

export default function Layout({ children }) {
  return (
    <div className="w-full">

      <Menu />

      {children}
    </div>
  );
}
