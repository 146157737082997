export default function ContactUs() {
  return (
    <div className="bg-white w-full md:max-w-[1200px] items-center">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 w-full mx-0 px-5 my-3 md:pl-5 md:pr-0">
          <label htmlFor="" className="font-bold ">
            نام
          </label>
          <input
            type="text"
            className="block border border-black px-5 py-1 w-full mt-3"
          />
        </div>
        <div className="md:w-1/2 w-full mx-0 px-5 md:pl-0 my-3 md:pr-5">
          <label htmlFor="" className="font-bold ">
            ایمیل
          </label>
          <input
            type="email"
            className="block border border-black px-5 py-1 w-full mt-3"
          />
        </div>
      </div>

      <div className=" w-full mx-0 px-5 md:px-0 my-3">
        <label htmlFor="" className="font-bold ">
          عنوان
        </label>
        <input
          type="text"
          className="block border border-black px-5 py-1 w-full mt-3"
        />
      </div>

      <div className=" w-full mx-0 px-5 md:px-0 my-3">
        <label htmlFor="" className="font-bold ">
          عنوان
        </label>
        <textarea
          type="text"
          className="block border border-black px-5 py-1 w-full mt-3"
          rows={12}
        />
      </div>
    </div>
  );
}
