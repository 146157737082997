import React from "react";

export default function Menu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const scrolltoHash = function (element_id) {
    const element = document.getElementById(element_id)
    element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  function HandleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <nav className="z-10 fixed top-0 bg-black animate-fade-down w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex items-center">
          {/* <img
            src="https://flowbite.com/docs/images/logo.svg"
            className="h-8 mr-3"
            alt="Flowbite Logo"
          /> */}
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
            داده نگار داتیس پارس
          </span>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 
          focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={`${isMenuOpen}`}
          onClick={HandleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div
          className={`w-full md:block md:w-auto animate-jump-in ${isMenuOpen ? "" : "hidden"}  md:mt-0 `}
          id="navbar-default"
        >
          <ul className=" 
          font-medium flex flex-col p-4 md:p-0 mt-4  md:flex-row md:space-x-8 md:mt-0 md:border-0 text-white text-center">
            <li>
              <span
                onClick={() => {window.scrollTo({top: 20, behavior: 'smooth'})}}
                className="cursor-pointer md:ml-7 block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-blue-700 md:p-0"
              >
                خانه
              </span>
            </li>
            <li>
              <span
                onClick={()=> {scrolltoHash("services")}}
                className="cursor-pointer block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-blue-700 md:p-0"
              >
                زمینه های کاری
              </span>
            </li>
            <li>
              <span
                onClick={()=> {scrolltoHash("projects")}}
                className="cursor-pointer block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-blue-700 md:p-0"
              >
                آخرین پروژه ها
              </span>
            </li>
            <li>
              <span
                onClick={()=> {scrolltoHash("customers")}}
                className="cursor-pointer block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-blue-700 md:p-0"
              >
                مشترییان
              </span>
            </li>
            <li>
              <span
                onClick={()=> {scrolltoHash("contactUs")}}
                className="cursor-pointer block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 hover:text-blue-700 md:p-0"
              >
                ارتباط با ما
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
