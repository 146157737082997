import "./global.css";

// import Head from "next/head";
// import Image from "next/image";
import Layout, { siteTitle } from "./components/layout";
import Menu from "./components/menu";
import headerNormal from "./util/images/header.jpg"
import headerMobile from "./util/images/mobile-back.jpg";
import Services from "./components/services";
import Customers from "./components/customers";
import Footer from "./components/footer";
import Projects from "./components/projectList";
import ScrollCarouselComponent from "./components/ScrollCarouselComponent";
import ContactUs from "./components/contactUsForm";


export default function App() {
  return (
    <Layout home>
      {/* <Head>
        <title>{siteTitle}</title>
      </Head> */}

      {/* header background desktop/mobile */}
      <div className="fixed top-0 w-full h-full -z-10" >
        <div className=" overflow-hidden hidden md:block">
          <img src={headerNormal} fill={true} alt="header" className="w-full"/>
        </div>
        <div className=" overflow-hidden md:hidden">
          <img src={headerMobile} fill={true} alt="header" className="w-full"/>
        </div>
      </div>

      {/* main content */}
      <div className="md:mt-[65vh] mt-[40vh] bg-white relative" id="services">
        <Services />
      </div>

      <div className="bg-white w-full" id="projects">
        <p className="text-xl font-bold text-center pt-5">پروژه های اخیر</p>
        <Projects />
      </div>

      <div className="w-full bg-white flex justify-center" id="customers">
        <div className="max-w-[1200px] overflow-hidden">
          <Customers />
        </div>
      </div>

      <div className="bg-white w-full flex flex-col justify-center items-center py-5" id="contactUs">
        <p className="text-xl font-bold text-center pt-5">ارتباط با ما</p>
        {/* contact us form */}
        <ContactUs />
      </div>

      <div className="w-full bg-black">
        <Footer />
      </div>
    </Layout>
  );
}
