import contentImage from '../util/images/services/content.png';
import learningImage from '../util/images/services/learning.png';
import programmingImage from '../util/images/services/programming.png';
import gameImage from '../util/images/services/game.png';
import photoImage from '../util/images/services/photo.png';
import animationImage from '../util/images/services/animation.png';
import adobeImage from '../util/images/services/adobe.png';
import connectImage from '../util/images/services/connect.png';
import solutionsImage from '../util/images/services/solutions.png';


const services = [
    {
        link: contentImage,
        title: 'تولید محتوای الکترونیکی',
        text: 'آموزش های تحت وب، موبایل و تحت ویندوز'
    },
    {
        link: learningImage,
        title: "سیستم مدیریت یادگیری",
        text: 'کلاس مجازی، سیستم مدیریت آموزش الکترونیکی، جلسات آنلاین'
    },
    {
        link: programmingImage,
        title: "برنامه نویسی",
        text: 'برنامه نویسی تحت وب php، asp.net و تحت موبایل'
    },
    {
        link: gameImage,
        title: ' بازی سازی ',
        text: ' بازی های دو بعدی، سه بعدی و تحت موبایل '
    },
    {
        link: photoImage,
        title: ' عکاسی ',
        text: ' تور مجازی، عکاسی صنعتی، عکاسی حرفه ای، محیط زیست '
    },
    {
        link: animationImage,
        title: ' انیمیشن ',
        text: ' انمیشن های دو بعدی و سه بعدی، موشن گرافیک و تیزر '
    },
    {
        link: adobeImage,
        title: ' نرم افزار adobe connect ',
        text: ' برگزاری آنلاین جلسات کاری - آموزشی - علمی - فرهنگی و ... '
    },
    {
        link: connectImage,
        title: 'خدمات جانبی adobe connect',
        text: 'سیستم ضبط اتوماتیک و تولید MP4 - مدیریت برگزاری کلاسهای و ....'
    },
    {
        link: solutionsImage,
        title: 'مشاهده و ارائه راهکارهای آموزش مجازی',
        text: 'چه سیستمی را چگونه، کجا و در چه شرایطی استفاده کنیم؟'
    },
];

export default function Services () {
    return (
        <div className="md:max-w-[1200px] md:mx-auto mx-4 bg-white rounded-lg flex flex-col 
        relative -top-16 border border-gray-200 shadow-sm w-[90wv]">

            <p className="text-xl font-bold pt-5 text-center">
                زمینه های کاری
            </p>

            <div className="flex flex-wrap justify-around my-2">

                {
                    services.map((service) => {
                        return (
                            <div className="w-full md:w-1/4  md:mx-8 my-4 flex flex-col justify-center text-center items-center" key={service.title}>
                                <img 
                                    src={service.link}
                                    width={90}
                                    height={90}
                                    className="my-3"
                                    alt={service.title}
                                />
                                <p className=" font-bold my-2"> {service.title} </p>
                                <p className="font-light text-gray-500"> {service.text} </p>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}