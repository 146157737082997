
export default function Projects() {
    return (
      <div className="md:max-w-[1200px] mx-auto flex lg:flex-row lg:justify-between flex-col items-center">
  
       
  <div className="rounded-md lg:w-[380px] h-[250px] w-4/5 overflow-hidden my-5">
          <div className="back1 jobs-card z-10">
            <div className="text-white absolute left-0 top-0 bg-black h-full w-full opacity-0 hover:opacity-80 flex flex-row justify-center items-center">
              <p className=""> موشن گرافیک های محیط زیستی </p>
            </div>
          </div>
        </div>
  
        <div className="rounded-md lg:w-[380px] h-[250px] w-4/5 overflow-hidden my-5">
          <div className="back2 jobs-card z-10">
            <div className="text-white absolute left-0 top-0 bg-black h-full w-full opacity-0 hover:opacity-80 flex flex-row justify-center items-center">
              <p className=""> گذری بر جلوه های محیط زیست ایران </p>
            </div>
          </div>
        </div>
  
        <div className="rounded-md lg:w-[380px] h-[250px] w-4/5 overflow-hidden my-5">
          <div className="back3 jobs-card z-10">
            <div className="text-white absolute left-0 top-0 bg-black h-full w-full opacity-0 hover:opacity-80 flex flex-row justify-center items-center">
              <p className=""> کبد چرب </p>
            </div>
          </div>
        </div>
  
      </div>
    );
  }
  