import React from "react";
import ScrollCarousel from "scroll-carousel-react";


import customerImage1 from "../util/images/customers/1.png"
import customerImage2 from "../util/images/customers/2.png"
import customerImage3 from "../util/images/customers/3.png"
import customerImage4 from "../util/images/customers/4.png"
import customerImage5 from "../util/images/customers/5.png"
import customerImage6 from "../util/images/customers/6.png"
import customerImage7 from "../util/images/customers/7.png"
import customerImage8 from "../util/images/customers/8.png"
import customerImage9 from "../util/images/customers/9.png"
import customerImage10 from "../util/images/customers/10.png"
import customerImage11 from "../util/images/customers/11.png"
import customerImage12 from "../util/images/customers/12.png"
import customerImage13 from "../util/images/customers/13.png"
import customerImage14 from "../util/images/customers/14.png"
import customerImage15 from "../util/images/customers/15.png"
import customerImage16 from "../util/images/customers/16.png"
import customerImage17 from "../util/images/customers/17.png"
import customerImage18 from "../util/images/customers/19.png"
import customerImage19 from "../util/images/customers/20.png"
import customerImage20 from "../util/images/customers/21.png"


const customerLogos = [
  {
    link: customerImage1 ,
    title: "دانشگاه شهید بهشتی",
  },
  {
    link: customerImage2 ,
    title: "قطب علمی آموزشی دانشگاه علوم پزشکی",
  },
  {
    link: customerImage3 ,
    title: "دانشگاه تهران",
  },
  {
    link: customerImage4 ,
    title: "دانشگاه علوم پزشکی زابل",
  },
  {
    link: customerImage5 ,
    title: "سازمان مدیریت پسماند شهرداری شیراز",
  },
  {
    link: customerImage6 ,
    title: "مرکز آموزش الکترونیک دانشگاه شهید بهشتی",
  },
  {
    link: customerImage7 ,
    title: "دانشگاه علم و صنعت",
  },
  {
    link: customerImage8 ,
    title: "سازمان حفاظت محیط زیست",
  },
  {
    link: customerImage9 ,
    title: "بنیاد شهید فارس",
  },
  {
    link: customerImage10 ,
    title: "مجموعه فنی تهران",
  },
  {
    link: customerImage11 ,
    title: "دانشگاه شیراز",
  },
  {
    link: customerImage12 ,
    title: "دانشگاه علمی کاربردی",
  },
  {
    link: customerImage13 ,
    title: "سازمان فنی حرفه ای کل کشور",
  },
  {
    link: customerImage14 ,
    title: "شرکت پلیمری آریا ساسول",
  },
  {
    link: customerImage15 ,
    title: "دانشگاه علوم پزشکی شیراز",
  },
  {
    link: customerImage16 ,
    title: "شبکه دنا",
  },
  {
    link: customerImage17 ,
    title: "شبکه خلیج فارس",
  },
  {
    link: customerImage18 ,
    title: "اداره آموزش مداوم دانشگاه علوم پزشکی شیراز",
  },
  {
    link: customerImage19 ,
    title: "اداره کل آموزش پرورش شیراز",
  },
  {
    link: customerImage20 ,
    title: "پزشکی قانونی",
  },
];

const ScrollCarouselComponent = () => {
  return (
    <ScrollCarousel
      autoplay
      autoplaySpeed={8}
      speed={8}
      onReady={() => console.log("I am ready")}
    >
      {customerLogos.map((item) => (
        <div
          key={item.id}
          className="bg-blue-300/20 border-2 border-blue-300/70 rounded h-36 w-48 flex flex-col 
          justify-center items-center text-center"
        >
          <img src={item.link} width={200} height={200} alt={item.title} />
          <p>{item.title}</p>
        </div>
      ))}
    </ScrollCarousel>
  );
};

export default ScrollCarouselComponent;
