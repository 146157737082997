export default function Footer() {
  return (
    <div className="w-full bg-black text-white flex md:flex-row md:container md:mx-auto justify-around py-10 flex-col">
      <div className="md:w-1/3 w-full mx-0 px-5 flex flex-col py-10">
        <p className="font-bold mb-5">درباره ما</p>
        <p className="text-justify">
          گروه داتیس با تحقیق و توسعه، تجربیات موفق چندین ساله فعالیت در زمینه
          های مختلف حوزه انفورماتیک (شامل: تولید محتوا، ارائه سامانه مدیریت
          یادگیری، ساخت آزمون، ساخت نرم افزارهای تحت موبایل و تحت وب، تولید
          انیمیشن، ساخت بازی، عکاسی حرفه ای و برنامه نویسی ) را کسب کرده است و
          با تلاش نیروهای خلاق و جوان خود آماده ارائه انواع خدمات در حوزه ذکر
          شده به شرکت ها و سازمان های دولتی و خصوصی و اشخاص حقیقی می باشد.
        </p>
      </div>

      <div className="md:w-1/3 w-full mx-0 px-5 flex flex-col py-10">
        <p className="font-bold mb-5">تماس با ما</p>
        <ul>
            <li className="my-3">98713-2350327 , 09177010078</li>
            <li className="my-3">Morteza.sattar@gmail.com</li>
            <li className="my-3">datispars@gmail.com@gmail.com</li>
            <li className="my-3">شیراز، خیابان انقلاب اسلامی، تقاطع نادر، طبقه سوم ساختمان ابزاران</li>
        </ul>
      </div>
    </div>
  );
}
